export const SEOCommonsData = {
  siteName: "Outscal",
  twitterUsername: "@outscal",
  twitterCardType: "summary_large_image",
  ogType: "website",
  locale: "en_US",
  outscalLogo:
    "https://outscal-assets.s3.ap-south-1.amazonaws.com/SEO/outscal-logo.jpg",
};

export const twitter = {
  handle: "@outscal",
  site: "@outscal",
  cardType: "summary_large_image",
};
